import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  vendorById: (id) => `/vendors/${id}`,
  vendorCustomers: (vendorId) => `vendors/${vendorId}/customers`,
  rosterView: () => 'vendors/rosterView',
  vendorAssigned: () => '/vendors/self',
  suggestionsByEquipmentId: (id) =>
    `/vendor-preferences/suggestions/asset/${id}`,
  categoryRostersForLocation: (locationId) =>
    `vendor-preferences/locations/${locationId}/servicecategories`,
  assetRostersForLocation: (locationId) =>
    `vendor-preferences/locations/${locationId}/assets`,
  categoryRosterById: (locationId, categoryId) =>
    `vendor-preferences/locations/${locationId}/servicecategories/${categoryId}`,
  locationRoster: (locationId) => `vendor-preferences/locations/${locationId}`,
  customerPreferences: (customerId) =>
    `vendor-preferences/customers/${customerId}`,
  addDoNotSuggestVendorForLocation: (locationId) =>
    `vendor-preferences/locations/${locationId}/doNotSuggest`,
  lookup: () => 'vendors/lookup',
  lookupDetails: (placeId) => `vendors/lookup/${placeId}`,
  vendor: () => 'vendors',
  search: () => 'vendors/search',
  ssoHistoryView: () => 'vendors/ssoHistoryView',
  eightySixVendors: () => 'vendors/eightySixVendors',
  getInternalTechs: () => 'vendors/internalTechnicianVendors',
  allPossibleVendorsForRequest: () => 'vendors/allPossibleVendorsForRequest',
  getCustomersAdmin: () => 'vendors/admin/search',
};

export async function getSuggestionsByEquipmentId(id) {
  return esApiV3
    .get(api.suggestionsByEquipmentId(id))
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getAllPossibleVendorsForRequest(query) {
  return esApiV3
    .get(api.allPossibleVendorsForRequest(), { params: query })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get all possible vendors for the request.',
      );
    });
}

export async function getVendorForUser(showError) {
  if (typeof showError === 'undefined') showError = true;
  return esApiV3
    .get(api.vendorAssigned())
    .then((result) => result.data)
    .catch((ex) => {
      if (showError) exception.showCustomError(ex);
    });
}

export async function getCustomersForVendor(vendorId) {
  return esApiV3
    .get(api.vendorCustomers(vendorId))
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function patchVendorById(id, data) {
  return esApiV3
    .patch(api.vendorById(id), data)
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getVendorById(id, query) {
  return esApiV3
    .get(api.vendorById(id), { params: query })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getVendorsForRosterViewByQuery(query) {
  return esApiV3
    .get(api.rosterView(), { params: query })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get vendors for roster view.',
      );
    });
}

export async function getCategoryRostersForLocation(locationId) {
  return esApiV3
    .get(api.categoryRostersForLocation(locationId))
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getAssetRostersForLocation(locationId) {
  return esApiV3
    .get(api.assetRostersForLocation(locationId))
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function saveCategoryRoster(
  locationId,
  categoryId,
  body,
  exclusions,
) {
  return esApiV3
    .put(api.categoryRosterById(locationId, categoryId), {
      [exclusions ? 'doNotSuggest' : 'roster']: body,
    })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getLocationRoster(locationId) {
  return esApiV3
    .get(api.locationRoster(locationId))
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getCustomerPreferences(customerId) {
  return esApiV3
    .get(api.customerPreferences(customerId))
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function saveLocationRoster(locationId, body, exclusions) {
  return esApiV3
    .put(api.locationRoster(locationId), {
      [exclusions ? 'doNotSuggest' : 'roster']: body,
    })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function saveDoNotSuggestVendorForLocation(locationId, body) {
  return esApiV3
    .post(api.addDoNotSuggestVendorForLocation(locationId), body)
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getVendorsLookup(searchString) {
  return esApiV3
    .get(api.lookup(), { params: { text: searchString } })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getVendorLookupDetails(placeId) {
  return esApiV3
    .get(api.lookupDetails(placeId))
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function createVendor(body) {
  return esApiV3
    .post(api.vendor(), body)
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

/**
 *
 * @param {string} searchText
 * @returns {Promise<any>}
 */
export async function searchVendors(searchText) {
  return esApiV3
    .get(api.search(), {
      params: { searchText },
    })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getVendorsForHistoryView({
  locationId,
  searchText,
  startDate,
  endDate,
}) {
  return esApiV3
    .get(api.ssoHistoryView(), {
      params: { locationId, searchText, startDate, endDate },
    })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(ex);
    });
}

export async function getInternalTechVendors() {
  return esApiV3
    .get(api.getInternalTechs())
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get vendors for roster view.',
      );
    });
}

export async function getEightySixVendors({ limit, offset, ...query }) {
  return esApiV3
    .get(api.eightySixVendors(), { params: { limit, offset, ...query } })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get eighty six vendors.',
      );
    });
}

export async function getVendorsAdmin(query) {
  try {
    const res = await esApiV3.get(api.getCustomersAdmin(), {
      params: query,
    });
    return res?.data;
  } catch (ex) {
    exception.showCustomError(ex, 'Error getting vendors');
  }
  return null;
}
