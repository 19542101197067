import { defineStore } from 'pinia';
import config from '@/config';
import authService from '@/service/auth.service';
import userProfileService from '@/service/user-profile.service';
import { ability } from '@/plugins/ability';
import { useCustomerStore } from './customerStore';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    configMode: config.mode,
    configVersion: config.version,
    userInfo: {},
    rolesAndRanks: [],
    isVendorWithUpdatePermissions: false,
    routeName: null,
    token: null,
    recaptcha: null,
  }),
  actions: {
    async getUserInfo() {
      const results = await userProfileService.getUserInfo();
      this.userInfo = results;
    },
    async loadUserCaslRules() {
      const rules = await authService.getUserCaslRules();
      ability.update(rules || []);
    },
    async unlinkLoginProvider(provider = '') {
      provider = provider.toLowerCase();
      await authService.unlinkLoginProvider(provider);
      const { userInfo } = this.state;
      userInfo.identities = userInfo.identities?.filter(
        (i) => i.identityProvider !== provider,
      );
      this.userInfo = userInfo;
    },
    async getAllRolesAndRanks() {
      const results = await authService.getRolesAndRanks();
      this.rolesAndRanks = results;
    },
    updateCaslPermissionState({ casl, value }) {
      this[casl] = value;
    },
    setRouteName(routeName) {
      this.routeName = routeName;
    },
    async setAuth(token, recaptchaToken) {
      this.token = token;
      this.recaptcha = recaptchaToken;
    },
  },
  getters: {
    hasUserInfo: (state) =>
      state.userInfo != null && Object.keys(state.userInfo).length > 0,
    isNotProd: (state) => state.configMode !== 'production',
    isProd: (state) => state.configMode === 'production',
    isLocal: (state) => state.configMode === 'local',
    isPartsTownUrl: () =>
      window?.location?.hostname?.includes('partstown.com') ||
      window?.location?.hostname?.includes('my-service360.com') ||
      window?.location?.hostname?.includes('service-integrator'),
    isPartsTown: (state) =>
      state.isPartsTownUrl ||
      // localStorage.getItem('is86PtInstance') === 'true' ||
      state.selectedCustomerSetting?.customer?.partnership === 'Parts Town' ||
      state.routeName === 'Logout',

    // soon we should make this based on something in state, or we make a list of all routes that should be unbranded
    isUnbrandedMode: () =>
      window.location.pathname.includes('sso-vendor-dispatch'),
    currentUser: (state) => state.userInfo,
    currentUserId: (state) => state.userInfo?.id,
    currentUserVendorId: (state) => state.userInfo?.vendors?.[0]?.id,
    hasAccessToMultipleCustomers: (state) =>
      state.userInfo.customerSettings?.filter(
        (cs) => cs.customer.status !== 'Churned',
      )?.length > 1,
    customerSettings: (state) => state.userInfo.customerSettings,
    selectedCustomerSetting: (state) => {
      const customerStore = useCustomerStore();
      const setting = state.customerSettings?.find(
        (cs) => cs.customerId === customerStore.selectedCustomer?.id,
      );
      return setting;
    },
    // leaving this hardcoded first customer setting because it is only used for initial setup/login
    currentUserCustomerId: (state) =>
      state.userInfo?.customerSettings?.[0]
        ? state.userInfo?.customerSettings?.[0].customerId
        : null,
    canAccessRequestReporting: (state) =>
      !state.selectedCustomerSetting?.customer?.isSelfService,
    canAccessInvoiceReporting: (state) =>
      state.selectedCustomerSetting?.customer?.canAccessInvoiceReporting,
    canAccessInsightsReporting: (state) =>
      state.selectedCustomerSetting?.customer?.canAccessInsightsReporting ||
      state.selectedCustomerSetting?.customer?.isSelfService,
    canAccessVendorInsightsReporting: (state) =>
      state.userInfo.id === 'f341ffc5-8ad6-40ae-945b-371d2f896624' ||
      (state.selectedCustomerSetting?.customer?.canAccessInsightsReporting &&
        state.userInfo?.sspRoles?.some(
          (r) =>
            r.sspRole === 'Area Supervisor - Facilities' ||
            r.sspRole === 'Director of Facilities',
        ) &&
        /// ////
        // temporary feature flag to only allow access to Savory (JP Dalton's accounts)
        /// ////
        [
          '8a1dc01d-423b-4aa1-8f37-b73ba2946fa3',
          '97e05a58-b66a-46dc-a5bb-ef94505ee3e5',
          '59382e40-1695-466a-b85e-818e93873efc',
          '78f39189-a913-4c0d-be5a-1e33cf7a04d5',
          '05bfc3a7-1689-4431-833a-784eb60190c0',
          '29a4ee5a-8b48-472e-a50c-7841c0e9596e',
          'ef677c39-6131-4693-8c0c-71db8caa89be',
          '73fb0843-1d10-436e-a62c-57aa6f00c55c',
          '063b7c0b-aa63-4151-a058-9292af1b92b1',
        ].includes(state.selectedCustomerSetting?.customer?.id)),
    canAccessPMInsightsReporting: (state) =>
      state.selectedCustomerSetting?.customer?.canAccessInsightsReporting &&
      !state.selectedCustomerSetting?.customer?.isSelfService &&
      /// ////
      // temporary feature flag to only allow access to Savory (JP Dalton's accounts)
      /// ////
      [
        '8a1dc01d-423b-4aa1-8f37-b73ba2946fa3',
        '97e05a58-b66a-46dc-a5bb-ef94505ee3e5',
        '59382e40-1695-466a-b85e-818e93873efc',
        '78f39189-a913-4c0d-be5a-1e33cf7a04d5',
        '05bfc3a7-1689-4431-833a-784eb60190c0',
        '29a4ee5a-8b48-472e-a50c-7841c0e9596e',
        'ef677c39-6131-4693-8c0c-71db8caa89be',
        '73fb0843-1d10-436e-a62c-57aa6f00c55c',
        '063b7c0b-aa63-4151-a058-9292af1b92b1',
      ].includes(state.selectedCustomerSetting?.customer?.id),

    currentUserRoles: (state) =>
      state.userInfo?.sspRoles?.map((r) => r.sspRole) ?? [],
    isBohUser: (state) => state.userInfo?.isBohUser,
    isLevelTwoOrUp: (state) => state.userInfo.highestRoleRank <= 2,
    gmAndAboveUser: (state) => state.userInfo.highestRoleRank <= 4,
    isInternalTechUser: (state) =>
      !!state.currentUserRoles.find((role) => role === 'Internal Technician'),
    isInternalTechLead: (state) =>
      !!state.currentUserRoles.find(
        (role) => role === 'Internal Technician - Lead',
      ),
    customerRolesUserCanEdit: (state) => {
      const userRank = state.userInfo.highestRoleRank;
      const mappedRoles = Object.entries(state.rolesAndRanks)
        // eslint-disable-next-line no-unused-vars
        ?.filter(([key, value]) => value.userTypes.includes('person'))
        ?.map(([key, value]) => ({
          label: key,
          value: key,
          disabled: value.rank < userRank,
        }));
      return mappedRoles;
    },
    vendorRolesUserCanEdit: (state) => {
      const userRank = state.userInfo.highestRoleRank;
      const mappedRoles = Object.entries(state.rolesAndRanks)
        // eslint-disable-next-line no-unused-vars
        ?.filter(([key, value]) => value.userTypes.includes('vendor'))
        ?.map(([key, value]) => ({
          label: key,
          value: key,
          disabled: value.rank < userRank,
        }));
      return mappedRoles;
    },
    vendorOnlyUser(state) {
      return (
        // leaving this hardcoded first customer setting
        state.userInfo?.customerSettings?.[0] == null &&
        state.userInfo?.vendors.length
      );
    },
    canViewDashboard: (state) => {
      const customerStore = useCustomerStore();
      return (
        (state.gmAndAboveUser || state.isInternalTechUser) &&
        !customerStore.isSelfServiceCustomer &&
        !state.vendorOnlyUser
      );
    },
  },
});
